import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import Religion from "@/class/Religion";
import Mtongue from "@/class/Mtongue";
import Caste from "@/class/Caste";
import Country from "@/class/Country";
import {constants} from "@/core/services/Constants";
import MatchBy from "@/class/MatchBy";
import {Mutation} from "vuex-module-decorators";
import store from "@/store";
import EduDetail from "@/class/EduDetail";
import Occupation from "@/class/Occupation";

export interface User {
    index_id: number,
    fb_id: string,
    matri_id: string,
    prefix: string,
    title: string,
    description: string,
    keyword: string,
    terms: string,
    email: string,
    password: string,
    cpassword: string,
    cpass_status: string,
    m_status: string,
    profileby: string,
    time_to_call: string,
    reference: string,
    username: string,
    firstname: string,
    lastname: string,
    gender: string,
    birthdate: Date,
    birthtime: Date,
    birthplace: string,
    tot_children: number,
    status_children: string,
    edu_detail: string,
    income: string,
    occupation: string,
    emp_in: string,
    designation: string,
    religion: number,
    caste: number,
    subcaste: string,
    gothra: string,
    star: string,
    moonsign: string,
    horoscope: string,
    manglik: string,
    m_tongue: number,
    height: number,
    weight: number,
    b_group: string,
    complexion: string,
    bodytype: string,
    diet: string,
    smoke: string,
    drink: string,
    languages_known: string,
    address: string,
    country_id: number,
    state_id: number,
    city: number,
    phone: string,
    mobile: string,
    contact_view_security: string,
    residence: string,
    father_name: string,
    mother_name: string,
    father_living_status: string,
    mother_living_status: string,
    father_occupation: string,
    mother_occupation: string,
    profile_text: string,
    looking_for: string,
    family_details: string,
    family_type: string,
    family_status: string,
    no_of_brothers: number,
    no_of_sisters: number,
    no_marri_brother: number,
    no_marri_sister: number,
    part_frm_age: number,
    part_to_age: number,
    part_bodytype: string,
    part_diet: string,
    part_smoke: string,
    part_drink: string,
    part_income: string,
    part_emp_in: string,
    part_occupation: string,
    part_designation: string,
    part_expect: string,
    part_height: number,
    part_height_to: number,
    part_complexion: string,
    part_mtongue: string,
    part_religion: string,
    part_caste: string,
    part_manglik: string,
    part_star: string,
    part_edu: string,
    part_country_living: string,
    part_state: string,
    part_city: string,
    part_resi_status: string,
    hobby: string,
    hor_check: string,
    hor_photo: string,
    photo_protect: string,
    photo_pswd: string,
    video: string,
    video_approval: string,
    video_url: string,
    video_view_status: string,
    photo_view_status: string,
    photo1: string,
    photo1_approve: string,
    photo2: string,
    photo2_approve: string,
    photo3: string,
    photo3_approve: string,
    photo4: string,
    photo4_approve: string,
    photo5: string,
    photo5_approve: string,
    photo6: string,
    photo6_approve: string,
    photo7: string,
    photo7_approve: string,
    photo8: string,
    photo8_approve: string,
    childAcceptance: string,
    reg_date: Date,
    ip: string,
    agent: string,
    agent_approve: string,
    last_login: Date,
    status: string,
    fstatus: string,
    logged_in: string,
    adminrole_id: number,
    franchised_by: string,
    adminrole_view_status: string,
    mobile_verify_status: string,
    token: string,

    age: string,
    userCountry: object,
    userReligion: object,
    userCaste: object,
    userEducation: object,
    userOccupation: object,
    userDesignation: object

}

interface Search {
    searchText: null,
        gender: string[],
        mstatus: string[],
        age_frm: null,
        age_to: null,
        height: null,
        height_to: null,
        religion: Religion[],
        caste: Caste[],
        mtongue: Mtongue[],
        country: Country[],
        status: [],
        part_smoke: [],
        part_drink: [],
        part_diet: [],
        part_edu: EduDetail[],
        part_occupation: Occupation[],
        part_resi_status: [],
        withPhoto: null
}
interface PaginationState<T> {
    items: T[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface UserState {
    users: PaginationState<User>;
}

const createPaginationState = <T>(): PaginationState<T> => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        errors: {},
        search: {} as Search,
        selectedUser: {} as User,
        users: createPaginationState()
    },


    getters: {
        currentSearch: (state) => state.search,
        users: (state) => state.users,
        selectedUser: (state) => state.selectedUser,
    },
    actions: {
        [Actions.GET_USERS_2]({state, commit}, {pageNo, pageSize}) {
            commit(Mutations.SET_ERROR, null);

            // Only reset pagination if filters change (not during pagination navigation)
            if (!pageNo) {
                commit(Mutations.RESET_PAGINATION);
            }

            // Merge the existing pageNo and filters

            let params = {};
            params['pageNo'] = state?.users?.pageNo;
            params['searchText'] = state?.search?.searchText;

            params['gender'] = state?.search?.gender;

            const mstatus = state?.search?.mstatus?.filter(obj => obj !== constants.ANY);
            params['mstatus'] = mstatus?.map(function(item) {
                return item;
            });

            params['age_frm'] = state?.search?.age_frm;
            params['age_to'] = state?.search?.age_to;

            params['height'] = state?.search?.height?.value;
            params['height_to'] = state?.search?.height_to?.value;

            const religion = state?.search?.religion?.filter(obj => obj?.religion_id !== 0);
            params['religion'] = religion?.map(function(item) {
                return item.religion_id;
            });
            const caste = state?.search?.caste?.filter(obj => obj.caste_id !== 0);
            params['caste'] = caste?.map(function(item) {
                return item.caste_id;
            });

            const mtongue = state?.search?.mtongue?.filter(obj => obj?.mtongue_id !== 0);
            params['mtongue'] = mtongue?.map(function(item) {
                return item?.mtongue_id;
            });

            const country = state?.search?.country?.filter(obj => obj?.country_id !== 0);
            params['country'] = country?.map(function(item) {
                return item?.country_id;
            });
            // fstatus
            const status = state?.search?.status?.filter(obj => obj !== constants.ANY);
            params['status'] = status?.map(function(item) {
                return item;
            });

            params['part_smoke'] = state?.search?.part_smoke;
            params['part_drink'] = state?.search?.part_drink;
            params['part_diet'] = state?.search?.part_diet;

            const edu = state?.search?.part_edu?.filter(obj => obj?.edu_id !== 0);
            params['part_edu'] = edu?.map(function(item) {
                return item?.edu_id;
            });

            const ocp = state?.search?.part_occupation?.filter(obj => obj?.ocp_id !== 0);
            params['part_occupation'] = ocp?.map(function(item) {
                return item?.ocp_id;
            });
            params['part_resi_status'] = state?.search?.part_resi_status;

            params['withPhoto'] = state?.search?.withPhoto;


            return ApiService.query("/v2/user", {params})
                .then((response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    const {data} = response;
                    commit(Mutations.SET_USERS, {
                        items: data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response?.data?.meta?.message);
                });
        },
        // [Actions.GET_LEAD]({state, commit}, leadId) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.get(`/v2/lead/${leadId}`)
        //         .then(({ data }) => {
        //             commit(Mutations.SET_SELECTED_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.UPDATE_LEAD]({state, commit}, { leadId, payload }) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.put(`/v2/lead/${leadId}`, payload)
        //         .then(({ data }) => {
        //             commit(Mutations.UPDATE_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.ADD_LEAD]({state, commit}, payload) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.post(`/v2/lead`, payload)
        //         .then(({ data }) => {
        //             commit(Mutations.ADD_LEAD, data?.data);
        //             return data?.data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.DELETE_LEAD]({ commit }, leadId) {
        //     commit(Mutations.SET_ERROR, null);
        //     return ApiService.delete(`/v2/lead/${leadId}`)
        //         .then(({data}) => {
        //             commit(Mutations.DELETE_LEAD, leadId);
        //             return data;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response?.data?.meta?.message);
        //         });
        // },
        // [Actions.SET_SELECTED_LEAD]({ commit }, lead) {
        //     commit(Mutations.SET_SELECTED_LEAD, lead);
        // },
        // [Actions.UPDATE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
        //     commit(Mutations.SET_PAGENO, pageNo);
        //     // dispatch(Actions.GET_MATCH_ACTIVITY);
        // },
        [Actions.UPDATE_USERS_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_PAGENO, pageNo);
            // dispatch(Actions.SEARCH_USER);
        },
        [Actions.CLEAR_USERS_2]({ commit }) {
            commit(Mutations.PURGE_USERS);
        },
        [Actions.UPDATE_SEARCH_2]({ commit, dispatch }: any, search: UserState) {
            commit(Mutations.SET_SEARCH, search);
            commit(Mutations.RESET_PAGINATION);
            // dispatch(Actions.SEARCH);
        },
        [Actions.CLEAR_SEARCH_2]({ commit }) {
            commit(Mutations.PURGE_SEARCH);
        },
        [Actions.UPDATE_USER_STATUS]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post("/admin/users/updateStatus", payload)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    commit(Mutations.UPDATE_USER_STATUS_MUTATION, response?.data);
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },

        [Actions.SEND_MATCHES]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post("/admin/users/sendMatches", payload)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        async [Actions.FETCH_SELECTED_USER]({state, commit}, id) {
            // const existingUser = state.selectedUser;

            // If user is already set, skip API call
            // if (existingUser && existingUser.index_id === parseInt(id)) {
            //     return existingUser;
            // }
            try {
                return ApiService.get(`/admin/users/${id}`)
                    .then((response) => {
                        commit(Mutations.SET_SELECTED_USER, response?.data);
                        return response?.data;
                    })
                    .catch(({response}) => {
                        commit(Mutations.SET_ERROR, response.data.error);
                    });
            } catch (error) {
                console.error("Error fetching user details:", error);
                throw error;
            }
        },

    },
    mutations: {
        [Mutations.SET_SELECTED_USER](state, user) {
            state.selectedUser = user;
        },
        [Mutations.SET_USERS](state: UserState, payload: { items: User[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.users.items = payload.items;
            state.users.itemCount = payload.itemCount;
            state.users.pageSize = payload.pageSize;
            state.users.pageNo = payload.pageNo;
            state.users.pageCount = payload.pageCount;
        },
        [Mutations.PURGE_SEARCH](state) {
            state.search = {};
        },
        [Mutations.SET_SEARCH](state, search) {
            state.search = search;
        },
        [Mutations.SET_PAGENO](state: UserState, pageNo: number) {
            state.users.pageNo = pageNo;
        },
        [Mutations.RESET_PAGINATION](state: UserState) {
            state.users = createPaginationState();
        },
        [Mutations.PURGE_USERS](state) {
            state.users = createPaginationState();
        },

        [Mutations.UPDATE_USER_STATUS_MUTATION]({state, commit}, payload) {
            const data = store.getters.users;
            const items = data?.items.map(user => {
                if (payload?.ids.includes(user.index_id)) {
                    return {...user, status: payload?.action};
                }
                return user;
            });
            store.dispatch(Actions.SET_USERS, {...data, items}, {root: true});
        },
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
    }
};
